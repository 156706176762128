body {
	font-family: 'Montserrat', sans-serif;
}

.brand-logo {
	width: 180px;
}

.full-width {
	width: 100%;
}

.margin-top-10 {
	margin-top: 10px;
}

.mt-10 {
	margin-top: 10px;
}

.mr-10 {
	margin-right: 10px;
}

.top-nav-dropdown {
	width: 250px;
	margin-right: 50px;
}

.email-campaign-pie-chart {
	height: 250px;
	padding: 20px;
}

.text-center {
	text-align: center;
}

.tox-statusbar__right-container {
	opacity: 0;
}

.form-check.success .form-check-input:checked {
	background-color: #2eb85c;
	border-color: #2eb85c;
}

.step.hidden {
	display: none;
}

.d-flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal-table {
	font-size: 14px;
}

.form-floating > .form-control {
	height: calc(3rem + calc(var(--cui-border-width) * 2));
	min-height: calc(3rem + calc(var(--cui-border-width) * 2));
	line-height: 1.1;
	padding: 0.7rem 0.5rem;
}

.form-floating > .form-label {
	padding: 0.7rem 0.5rem;
}

.logo-container {
	display: flex;
	justify-content: center;
	margin-bottom: 40px;
}

.logo-container img {
	width: 60%;
}

.header-text {
	text-align: center;
}

.text-decoration-none {
	text-decoration: none;
}

.round-profile-img {
	border-radius: 50%;
	border: 1px solid #d9dbdf;
	padding: 10px;
	width: 250px;
}

.card.profile-form {
	background: transparent;
	border: none;
	box-shadow: none;
}

.profile-img-container {
	padding: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.profile-img-container img {
	width: 150px;
}

.overflow-text {
	display: inline-block;
	width: 80%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.template-preview-img {
	height: 225px;
	object-fit: contain;
}

.training-preview-img {
	height: 225px;
}

.training-preview-poster {
	position: relative;
	cursor: pointer;
}

.training-play-icon {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	color: white;
}

.template-preview-poster {
	position: relative;
	cursor: pointer;
}

.template-preview-icon {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	color: black;
}

.min-w-300 {
	min-width: 300px;
}

.template-dropdown-actions .dropdown-toggle::after {
	display: none;
}

#import_group_employee_modal button.btn.btn-transparent {
	visibility: hidden;
}

.input-group .form-multi-select {
	width: 170px !important;
	padding-right: 30px !important;
}

.input-group .form-multi-select-dropdown {
	width: 200px;
}

::-ms-input-placeholder {
	/* Edge 12-18 */
	color: #999999 !important;
	font-size: 14px;
}

::placeholder {
	color: #999999 !important;
	font-size: 14px;
}

.form-multi-select.form-multi-select-with-cleaner {
	padding-top: 8px !important;
}

.card-text p.mb-1.text-description {
	max-height: 80px;
	text-overflow: ellipsis;
	overflow: scroll;
}

.login-footer a {
	text-decoration: none;
	color: $gray-900;
}

.d-flex.login-footer * {
	margin-right: 10px;
}

.forgot-password-text {
	text-align: right;
}

.forgot-password-text a {
	color: $primary;
	text-decoration: none;
	cursor: pointer;
}

.card.p-6 {
	padding: 50px 45px;
}

.text-decoration-none {
	cursor: pointer;
}

.login-container {
	min-height: 600px;
}

svg.app_icon {
	fill: $primary;
	height: 50px;
	width: 50px;
}

.widget_success {
	background-color: #0d650d !important;
}

.widget_info {
	background-color: rgb(24 88 151) !important;
}

.widget_warning {
	background-color: rgb(236 170 26) !important;
}

.widget_danger {
	background-color: rgb(241 65 65) !important;
}

.form-floating > label {
	top: -12px !important;
	font-size: 13px !important;
}

.form-floating > input::placeholder {
	font-size: 12px !important;
}

.pd-5{
	padding: 5px;
}