.wrapper {
  width: 100%;
  // @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  padding-left: 16rem;
  will-change: auto;
  transition: padding .15s;
  // @include transition(padding .15s);
}

.wrapper-unfoldable {
  width: 100%;
  // @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  padding-left: 0;
  will-change: auto;
  transition: padding .15s;
  // @include transition(padding .15s);
}
